.works-container {
    width: 75%;
    height: 100%;
    padding: 2% 3% 0 3%;
    position: relative;
    overflow: hidden;

    .works-title {
        height: 40px;
        width: 100%;
        margin-bottom: 20px;

        h3 {
            font-size: 1.5em;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            &::after {
                display: block;
                content: "";
                height: 1.5px;
                width: 10%;
                background-color: #C2B887;
                margin-top: 12px;
            }
        }
    }

    // Carousel configuration parameters
    $n: 5;
    $item-width: 400px;
    $item-separation: 80px;
    $viewer-distance: 500px;

    // Derived variables
    $theta: calc(2 * 3.141592653589793 / $n); 
    $apothem: 482.842712474619px;

    .works-carousel {
        height: calc(100% - 60px);
        width: 100%;
        padding: 20px;
        perspective: $viewer-distance;
        display: flex;
        flex-direction: column;
        align-items: center;
        > * {
            flex: 0 0 auto;
        }
        
        figure {
            height: 80%;
            margin: 0;
            width: $item-width;
            transform-style: preserve-3d;
            transition: transform 0.5s;
            transform-origin: 50% 50% (-$apothem);

            .rotating-card {

                height: 100%;

                .legend {
                    height: 41px;
                    width: 100%;
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 20px;
        
                    &::before {
                        content: "";
                        display: block;
                        height: 15px;
                        width: 5px;
                        background-color: $color_secondary;
                        margin-right: 10px;
                    }
                }
                
                .works-img {
                    height: calc(100% - 41px);
                    position: relative;
                    margin: 0 calc($item-separation / 2);

                    .work-detail {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        z-index: 10;
                        padding: 5%;
                        background-color: $transparent_blue;
                        display: flex;
                        justify-content: center;
                        pointer-events: none;

                        .detail-content {
                            width: 100%;
                            padding: 0 5%;
                            height: fit-content;
                            margin-top: 25%;

                            h5 {
                                font-size: 1.6em;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                text-align: center;
    
                                &::after {
                                    display: block;
                                    content: "";
                                    height: 2px;
                                    width: 25%;
                                    background-color: #C2B887;
                                    margin: 10px 0 20px 0;
                                }
                            }
    
                            .detail-info, .detail-wood, .detail-size {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 15px;
                                margin-bottom: 20px;
                            }
                        }
                    }

                    img {
                        height: 100%;
                        width: 100%;
                        box-sizing: border-box;
                        opacity: 0.95;
                        cursor: pointer;
                    }
                    
                }
                

                &:not(:first-of-type) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    transform-origin: 50% 50% (-$apothem);
                }
                
                @for $i from 2 through $n {
                    &:nth-child(#{$i}) {
                        transform: rotateY(#{($i - 1) * $theta}rad);
                    }
                }

            }
        }

        .carousel-nav {
            height: calc(20% - 32px);
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 32px 0 0 0;
            
            button {
                height: 40px;
                width: 85px;
                margin: 0 7px;
                cursor: pointer;
                color: #fff;
                background: none;
                border: 1px solid;
                letter-spacing: 1px;
                padding: 5px 10px;
            }
        }
    }
}