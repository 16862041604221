html {
    background-color: $color_primary;
    overflow-y: scroll;
    overflow-y: overlay;
    scroll-behavior: smooth;
}

body {
    height: auto;
    width: calc(100vw - 17px);
    &::-webkit-scrollbar {
        width: 17px;
    }
    &::-webkit-scrollbar-track {
        background: $transparent_tertiary;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $transparent_beige;   
        border-radius: 20px;     
    }
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    color: #fff;
}

section {
    min-height: 750px;
}

.background {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    opacity: 0.75;

    img {
        height: 100%;
        width: 100%;
    }
}

img, image {
    object-fit: cover;
    display: block;
}

p, li, a, h4, label {
    font-family: "Abel", sans-serif;
}

a {
    text-decoration: none;
}

h1, h2, h3, h5, button {
    font-family: 'Arapey', serif;
    font-weight: normal;
}

ul {
    list-style-type: none;
}

.inactive-nav {
    &::before {
        display: inline-block;
        position: absolute;
        content: "";
        width: 5px;
        height: 15px;
        background-color: #857B47;
    }

    &:hover {   
        &::before {
            display: inline-block;
            position: absolute;
            content: "";
            animation: rotateElt 1s ease-out forwards;
            width: 15px;
            height: 15px;
            background-color: $color_secondary;
        }
    }
}

.active-nav {
    &::before {
        display: inline-block;
        position: absolute;
        content: "";
        animation: rotateElt 1s ease-out forwards;
        width: 15px;
        height: 15px;
        background-color: $color_secondary;
    }
}
