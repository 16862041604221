header {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px 0 5px;
    background-color: $transparent_blue;
    z-index: 10;

    .logo-container {
        height: 100%;
        width: 20%;
        min-width: 200px;
        display: flex;
        align-items: center;
        opacity: 0.75;

        svg {
            display: block;
            height: 80%;
            width: 50%;
        }

        h3 {
            font-size: 1.4em;
            width: 60%;
            color: '#fff';
            padding-left: 5px;

            span {
                color: $color-secondary;
            }
        }
    }

    #desktop-nav {
        height: 100%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 1.5em;
        

        ul {
            list-style-type: none;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            li {
                font-family: 'Arapey', serif;
                color: #fff;
                height: 100%;
                width: 25%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top: 3px solid transparent;
                cursor: pointer;

                &:hover {
                    border-top: 3px solid $color-secondary;
                }
            }
        }
    }
}