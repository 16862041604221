.all-services-container {
    display: flex;
    flex-direction: column;

    .all-services-nav {
        position: fixed;
        height: 100vh;
        width: 200px;
        display: flex;
        align-items: center;
        background-color: #1a2237;
        padding: 0 10px;
    
        ul {
            li {
                height: 30px;
                width: fit-content;
                display: flex;
                align-items: center;
                font-size: 1.1em;
                cursor: pointer;
                margin-bottom: 25px;
    
                span {
                    margin-left: 30px;
                }
            }
        }
    }
    
    .all-services-content {
        margin-bottom: 25px;
        width: calc(100% - 200px);
        align-self: flex-end;
    
        section {
            min-height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
    
            .all-services-title {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center; 
                &::after {
                    display: block;
                    content: "";
                    height: 1px;
                    width: 150px;
                    position: absolute;
                    bottom: -10px;
                    background-color: $color_secondary;
                }
            }
            .all-services-subtitle {
                text-align: center;
                margin: 25px 0;
    
                h3 {
                    font-family: "Abel", sans-serif;
                }
    
                span {
                    font-weight: bold;
                    color: $color_secondary;
                }
            }
        
            .all-services-img-container {
                width: 94%;
                max-width: 400px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 25px;
        
                img {
                    height: 100%;
                    width: 100%;
                    min-height: 250px;
                }
            }
        }
        
    }
}

