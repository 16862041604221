#contact {
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;

    .contact-container {
        width: 25%;
        border-right: 1px solid $transparent_beige;

        .contact-title {
            height: 200px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            svg {
                margin-bottom: 20px;

                rect {
                    opacity: 0;
                }            
            }

            .diamond-active {
                width: 100%;
                padding: 0 10%;

                rect{

                    &:nth-child(1) {
                        animation: opa0to1 1s linear forwards 1.40s;
                    }
                    &:nth-child(2) {
                        animation: opa0to1 1s linear forwards 1.05s;
                    }
                    &:nth-child(3) {
                        animation: opa0to1 1s linear forwards 0.7s;
                    }
                    &:nth-child(4) {
                        animation: opa0to1 1s linear forwards 0.35s;
                    }
                    &:nth-child(5) {
                        animation: opa0to1 1s linear forwards 0s;
                    }
                }
            }
        }
        
        .contact-logo {
            display: flex;
            justify-content: center;
            margin-bottom: 25px;

            svg {
                width: 25%;
                height: 25%;
                opacity: 0.75;
            }
        }

        .contact-nav {
            height: calc(100% - 150px);
            width: 100%;

            h4 {
                font-weight: normal;
                font-size: 1.2em;
                text-align: center;
                margin-bottom: 50px;
            }

            ul {
                list-style-type: none;
                font-size: 0.95em;
                padding: 0 8%;

                li {
                    height: fit-content;
                    width: 100%;
                    margin-bottom: 25px;
                    display: flex;
                    align-items: center;
                    font-size: 1.1em;

                    svg {
                        height: 28px;
                        width: 28px;
                        margin-right: 12px;
                    }

                    &:nth-child(3) {
                        word-break: break-all;
                    }
                }
            }
        }
    }

    .form-container {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .contact-us {
            height: 150px;
            max-width: 800px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                font-size: 1.5em;
                font-family: 'Arapey', serif;
                padding: 0 20px;

                &:nth-child(2) {
                    color: $color-secondary;
                    margin-top: 10px;
                }
            }
            
        }

        form {
            display: flex;
            flex-wrap: wrap;
            background-color: $color_tertiary;
            padding: 5%;
            max-width: 800px;

            h4 {
                width: 100%;
                font-weight: normal;
                font-size: 1.2em;
            }

            .left-column, .right-column {
                width: 50%;
                padding: 0 10%;
                margin: 25px 0;
                .input-container {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;
                    input {
                        color: #1E1E1E;
                    }

                    .error-msg {
                        font-size: 0.9em;
                        color: #ff5435;
                    }
                }
            }

            .wishes-container {
                display: flex;
                justify-content: space-evenly;
                width: 100%;
                margin: 25px 0;

                .inside-choices, .outside-choices {
                    background-color: #fff;
                    width: 35%;
                    
                    h5 {
                        font-size: 1em;
                        height: 45px;
                        background-color: $color_secondary;
                        color: #1E1E1E;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .inside-inputs, .outside-inputs {
                        padding: 3%;

                        input {
                            margin-right: 5px;
                            accent-color: $color-secondary;
                            cursor: pointer;
                        }
                    }

                    label {
                        color: #1E1E1E;
                    }

                    textarea {
                        color: #1E1E1E;
                        resize: none;
                        width: 100%;
                    }
                }
            }

            .message-container {
                margin: 25px 0;
                width: 100%;
                display: flex;
                justify-content: center;

                textarea {
                    color: #1E1E1E;
                    resize: none;
                    width: 50%;
                    padding: 5px;
                }
            }

            .btn-container {
                margin: 25px 0;
                width: 100%;
                display: flex;
                justify-content: center;

                input {
                    background-color: $color-secondary;
                    width: 120px;
                    height: 40px;
                    border: none;
                    cursor: pointer;
                    transition: 300ms;

                    &:hover {
                        transform: scale(1.03);
                    }
                }
            }
        }
        
        
    }
}