.home-slider {
    height: 100%;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    position: relative;

    .company-info {
        height: 100%;
        width: 100vw;
        flex-shrink: 0;
    
        .home-left-column {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
    
            .main-info {
                height: fit-content;
                min-height: 275px;
                width: 32%;
                min-width: 485px;
                margin-left: 15%;
                background-color: $transparent_blue;
                box-shadow: inset 10px 0 $color_secondary;
                padding: 17px 40px 10px 40px;
                display: flex;
                flex-direction: column;
    
                h1 {
                    font-size: 2.4em;
                    font-weight: normal;
                    margin-bottom: 25px;
                    padding-top: 8px;
    
                    span {
                        color: $color_secondary;
                    }
                }
    
                p {
                    font-size: 1.4em;
                    font-family: 'Arapey', serif;
                    line-height: 32px;
                    margin-bottom: 25px;

                    strong {
                        font-weight: normal;
                    }
                }
    
                .contact-redirection {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-grow: 1;
                    margin-bottom: 25px;
    
                    button {
                        background-color: transparent;
                        border: none;
                        cursor: pointer;
                        font-size: 1.3em;
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}

// Slide 1 depuis slide 2
@keyframes slide1from2 {
    from {
        transform: translateX(-100vw);
    }
    to {
        transform: translateX(0);
    }
}

// Slide 2 depuis slide 1
@keyframes slide2from1 {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100vw);
    }
}

// Slide 3 depuis slide 2
@keyframes slide3from2 {
    from {
        transform: translateX(-100vw);
    }
    to {
        transform: translateX(-200vw);
    }
}

// Slide 1 depuis slide 3
@keyframes slide1from3 {
    from {
        transform: translateX(-200vw);
    }
    to {
        transform: translateX(0);
    }
}

// Slide 3 depuis slide 1
@keyframes slide3from1 {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-200vw);
    }
}

// Slide 2 depuis slide 3
@keyframes slide2from3 {
    from {
        transform: translateX(-200vw);
    }
    to {
        transform: translateX(-100vw);
    }
}