.product-card {
    height: 16%;
    width: 50%;
    display: flex;
    align-items: center;
    margin: 5px 0;
    opacity: 0;

    &:nth-child(1) {
        z-index: 5;
    }
    &:nth-child(2) {
        z-index: 4;
    }
    &:nth-child(3) {
        z-index: 3;
    }
    &:nth-child(4) {
        z-index: 2;
    }
    &:nth-child(5) {
        width: 100%;
    }

    .product-img {
        height: 75px;
        width: 75px;

        img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .product-title {
        height: 75px;
        min-width: 65px;
        font-size: 1.2em;
        color: #fff;
        margin-left: 15px;
        display: flex;
        align-items: center;

        h4 {
            font-weight: normal;
            max-width: 100px;
        }
    }
}
