#about {
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    .about-container {
        height: 100%;
        width: 25%;

        .about-title {
            height: 200px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 60px;

            svg {
                margin-bottom: 20px;

                rect {
                    opacity: 0;
                }            
            }

            h2 {
                height: 27px;
            }

            .diamond-active {
                width: 100%;
                padding: 0 10%;

                rect{

                    &:nth-child(1) {
                        animation: opa0to1 1s linear forwards 1.40s;
                    }
                    &:nth-child(2) {
                        animation: opa0to1 1s linear forwards 1.05s;
                    }
                    &:nth-child(3) {
                        animation: opa0to1 1s linear forwards 0.7s;
                    }
                    &:nth-child(4) {
                        animation: opa0to1 1s linear forwards 0.35s;
                    }
                    &:nth-child(5) {
                        animation: opa0to1 1s linear forwards 0s;
                    }
                }
            }
        }

        .about-nav {
            height: calc(100% - 200px);
            width: 100%;
            padding: 0 20%;

            ul {
                li {
                    height: 30px;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    font-size: 1.1em;
                    cursor: pointer;
                    margin-bottom: 25px;

                    span {
                        margin-left: 35px;
                    }
                }
            }
        }
    }
    
    .about-img-container {
        width: 75%;
        position: relative;
        background-image: url('../../Media/Img/main/IMG_1079.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(18, 29, 39, 0.7);
        background-blend-mode: multiply;

        .about-content {

            .content-title {
                height: 200px;
                display: flex;
                align-items: center;

                h3 {
                    height: 97px;
                    width: 100%;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.9em;
                    opacity: 0;
    
                    &::after {
                        display: block;
                        content: "";
                        height: 1px;
                        width: 6%;
                        position: absolute;
                        bottom: 16px;
                        background-color: $color_secondary;
                    }
    
                    span {
                        color: $color_secondary;
    
                        &:nth-child(2) {
                            margin-left: 7px;
                        }
                    }
                }
                
            }

            .arrow-text {
                height: calc(100% - 100px);
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 0 15%;
                opacity: 0;
                padding-bottom: 60px;

                svg {
                    height: 20px;
                    width: 61px;
                    position: relative;
                    top: 7.5px;
                    padding-right: 12px;
                }

                h4 {
                    font-size: 1.4em;
                    font-weight: normal;
                }

                p {
                    width: calc(100% - 61px);
                    font-size: 1.2em;
                    line-height: 32px;

                    strong {
                        font-weight: normal;
                    }
                    
                    span {
                        font-weight: bold;
                        color: $color_secondary;
                    }
                }

                h5 {
                    font-family: "Abel", sans-serif;
                    font-size: 1.2em;
                    font-weight: bold;
                    margin-bottom: 5px;
                    color: $color_secondary;
                }

                .about-contact {
                    margin-bottom: 25px;
                }

                ul {
                    li {
                        margin-bottom: 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }

                .text-separation {
                    width: 100%;
                    height: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 35px 0;

                    .colored-line {
                        width: 20%;
                        height: 1px;
                        background-color: $color_secondary;
                    }
                }

                .about-btn {
                    background-color: $color-secondary;
                    width: 120px;
                    height: 40px;
                    border: none;
                    cursor: pointer;
                    transition: 300ms;
                    margin-top: 25px;
                    font-family: "Abel", sans-serif;
                    font-size: 0.9em;

                    &:hover {
                        transform: scale(1.03);
                    }
                }

            }
        }

        .content-appears {
            animation: modalAppears 3s ease forwards;
        }
    }
}

@keyframes opa0to1 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@keyframes modalAppears {
    from {
        transform: translateX(-50px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes rotateElt {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
        transform: rotate(45deg);
    }
}