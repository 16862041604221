#achievements {
    height: calc(100vh);
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    position: relative;

    .achievements-container {
        height: 100%;
        width: 25%;

        .achievements-title {
            height: 200px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 60px;

            svg {
                margin-bottom: 20px;

                rect {
                    opacity: 0;
                }            
            }

            .diamond-active {
                width: 100%;
                padding: 0 10%;

                rect{

                    &:nth-child(1) {
                        animation: opa0to1 1s linear forwards 1.40s;
                    }
                    &:nth-child(2) {
                        animation: opa0to1 1s linear forwards 1.05s;
                    }
                    &:nth-child(3) {
                        animation: opa0to1 1s linear forwards 0.7s;
                    }
                    &:nth-child(4) {
                        animation: opa0to1 1s linear forwards 0.35s;
                    }
                    &:nth-child(5) {
                        animation: opa0to1 1s linear forwards 0s;
                    }
                }
            } 
        }

        .achievements-nav {
            height: calc(100% - 200px);
            width: 100%;
            padding: 0 12%;

            ul {

                li {
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    font-size: 1.1em;
                    cursor: pointer;
                    margin-bottom: 25px;

                    span {
                        margin-left: 35px;
                    }
                }
            }
        }
    }
}