
@keyframes imgAppears {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.3;
    }
}


@keyframes contentAppears {
    from {
        transform: translateX(-10px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes toOpacity1 {
    0% {
        opacity: 0;
    }
    78% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
