/* Grande tablettes (portrait and landscape) ----------- */
@media only screen and (max-device-width : 1024px) {
    #root {
        header {
            #desktop-nav {
                display: none;
            }

            .hamburger-menu {
                position: relative;

                #tablet-nav {
                    position: absolute;
                    left: -86px;
                    background-color: #121C27;

                    li {
                        padding: 13px 28px;

                        &:active {
                            background-color: $color-secondary;
                        }
                    }
                }
            }
        }   
    }
}

/* Petites tablettes (portrait and landscape) ----------- */
@media only screen and (max-device-width : 768px) {
    #root {

        section {
            min-height: auto;
        }
        
        .home-left-column {
            background-color: rgba(18, 28, 39, 0.6);
            justify-content: center;

            .main-info {
                height: 285px;
                width: 80%;
                min-height: auto;
                min-width: auto;
                margin: 0;
                padding: 0;
                background: none;
                box-shadow: none;

                h1 {
                    text-align: center;
                }
            }
        }  

        #about, #services, #achievements, #contact {
            min-height: 870px;
            flex-direction: column;

            .about-container, .services-container, .achievements-container, .contact-container {
                height: 140px;
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 25px;
                border: none;

                .about-title, .services-title, .achievements-title, .contact-title {
                    height: 100%;
                    width: 35%;
                    min-width: 200px;
                    margin: 0;
                }

                .contact-logo {
                    display: none;
                }
            }

            .about-nav, .services-nav, .achievements-nav {
                height: 100%;
                width: 65%;
                padding: 0;

                ul {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;

                    li {
                        margin: 0;
                        height: 50%;
                    }
                }
            }

            .achievements-nav {

                ul {
                    flex-direction: row;

                    li {
                        height: auto;
                        width: 40%;
                        margin: 0 5%;
                    }
                }
            }

            .contact-container {
                height: auto;
                min-height: 140px;

                .contact-nav {
                    height: 100%;
                    width: 65%;
                    display: flex;
                    align-items: center;
    
                    h4 {
                        display: none;
                    }
    
                    ul {
                        height: 100%;
                        width: 100%;
                        padding: 0 5%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
    
                        li {
                            margin: 0;
                            margin-bottom: 10px;
                        }
                    }
                }

            }
            

            .about-img-container, .products-container, .works-container {
                height: calc(100% - 160px);
                width: 100%;

                .products-list {
                    margin-left: 5%;
                    min-width: 425px;
                    width: 72%;

                    .product-card:nth-child(2) .detailed-services {
                        top: 91px;
                        left: -22px;
                    }
    
                    .product-card:nth-child(4) .detailed-services {
                        top: 91px;
                        left: -22px;
                    }
                }
                
            }

            .about-content {
                .content-title {
                    height: 140px;
                }

                .arrow-text {
                    height: calc(100% - 140px);
                }
            }

            .form-container {
                width: 100%;

                #contact-form {
                    margin: 0 5%;

                    .inside-choices, .outside-choices {
                        width: 40%;
                    }
                }
            }
        }

        .all-services-nav {
            width: 115px;

            ul li {
                font-size: 0.9em;

                span {
                    margin-left: 27px;
                }
            }
        }

        .all-services-content {
            width: calc(100% - 115px);
        }
    }
}

/* Mobiles (portrait and landscape) ----------- */
@media only screen and (max-device-width : 480px) {
    body {
        height: auto;
        width: 100vw !important;
        &::-webkit-scrollbar {
            width: 0px;
        }
    }

    #root {
        header {
            #desktop-nav {
                display: none;
            }

            .hamburger-menu {
                position: relative;

                #tablet-nav {
                    position: absolute;
                    left: -86px;
                    background-color: #121C27;

                    li {
                        padding: 13px 28px;

                        &:active {
                            background-color: $color-secondary;
                        }
                    }
                }
            }
        }     

        #about {
            .about-container {
                .about-title {
                    min-width: 140px;
                }
                .about-nav {
                    display: flex;
                    justify-content: center;
                    ul {
                        flex-wrap: nowrap;
                    }
                }
            }

            .about-img-container {

                .arrow-text {
                    justify-content: center;
                    padding: 0 0 25px 0;

                    svg {
                        display: none;
                    }
                }
            }
            
        }

        #services {
            .services-container {
                .services-title {
                    min-width: 140px;
                }
                .services-nav {
                    display: flex;
                    justify-content: center;
                }
            }

            .products-container {
                .products-list {
                    min-width: auto;
                    width: 94%;
                    margin: 0 3%;

                    .products {
                        padding: 0 3%;

                        .product-card {

                            &:nth-child(2) {
                                justify-content: flex-end;
                            }

                            &:nth-child(4) {
                                justify-content: flex-end;
                            }

                            .product-img {
                                height: 60px;
                                width: 60px;
                            }
                        }
                    }

                    .products-type {
                        h3 {
                            padding: 0 10%;
                        }
                    }
                }
            }
            
        }

        #achievements {
            .achievements-container{
                height: auto;
                min-height: 140px;
                .achievements-title{
                    min-width: 160px;
                }

                .achievements-nav {
                    display: flex;
                    justify-content: center;
                    ul {
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: space-evenly;
                        padding: 0 8%;
    
                        li {
                            width: auto;
                            margin: 5px 0;
                        }
                    }
                    
                }
            }
        }

        #contact {
            .contact-container {
                .contact-title {
                    min-width: 160px;
                }

                .contact-nav {
                    ul {
                        li {
                            svg {
                                height: 23px;
                                width: 23px;
                            }
                        }
                    }
                }
            }

            .form-container {

                #contact-form {
                    padding: 0 3%;

                    .left-column, .right-column {
                        padding: 0 5%;
                    }
    
                    .wishes-container {
                        justify-content: space-between;
    
                        .inside-choices, .outside-choices {
                            width: 48%;
                        }
                    }

                    .message-container {
                        textarea {
                            width: 75%;
                        }
                    }
                }
            }
        }   
        
        footer {
            .footer-column {
                width: 100%;
                min-height: auto;

                h4 {
                    text-align: center;
                    margin: 18px 0;
                }

                ul, p {
                    text-align: center;
                    padding: 0 5%;
                }

                .social-networks {
                    justify-content: center;
                }
            }

            .signature {
                margin-top: 10px;
                flex-wrap: wrap;
                gap: 10px;
                justify-content: center;

                .terms {
                    p:nth-child(1) {
                        width: 45%;
                    }
                    span {
                        width: 10%;
                        text-align: center;
                    }
                    p:nth-child(3) {
                        width: 45%;
                    }
                }
            }
        }
    }
}