footer {
    height: auto;
    width: 100%;
    background-color: $color_tertiary;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .footer-column {
        height: auto;
        min-height: 250px;
        width: 20%;
        margin-bottom: 20px;

        h4 {
            font-weight: normal;
            font-size: 1.5em;
            margin: 27px 0;
        }

        ul {
            list-style-type: none;

            li {
                margin-bottom: 10px;

                &:nth-child(3) {
                    word-break: break-all;
                }
            }
        }

        &:nth-child(2) p, &:nth-child(3) p {
            line-height: 27px;
        }

        .social-networks {
            display: flex;
            gap: 25px;
            margin-top: 16px;

            img {
                height: 35px;
                width: 35px;
                cursor: pointer;
            }
        }
    }

    .signature {
        height: auto;
        width: 100%;
        padding: 20px 0;
        margin: 0 7%;
        border-top: 1px solid rgba(194, 184, 135, 0.7);
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.95em;
        gap: 20px;

        div:nth-child(1) p, div:nth-child(2) p, div:nth-child(2) span{
            color: #CCCCCC;
        }

        .terms {
            display: flex;
            align-items: center;

            p {
                text-align: center;
                cursor: pointer;

                &:hover {
                    color: $color-secondary;
                }
            }

            span {
                margin: 0 5px;
            }
        }
    }
}