#cgv-container {
    margin: 20px 5%;

    .brand-logo {
        margin-bottom: 20px;

        h1 {
            text-align: center;
        }
    }

    .cgv-title {
        margin-bottom: 20px;
    }

    .cgv-part {
        margin-bottom: 10px;

        h3 {
            margin-bottom: 5px;
        }
    }
}