.loader-container {
    background-color: $color_primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
    
    h1 {
        font-size: 50px;
        animation: blink-leaf 2s ease-in-out 1 forwards 2.6s;
        opacity: 0;
        margin-top: 10px;
        text-align: center;

        span {
            color: #9A8F53;
        }
    }

    .btn-container {
        margin-top: 25px;

        button {
            background-color: transparent;
            width: 135px;
            height: 40px;
            border: none;
            opacity: 0;
            animation: blink-leaf 2s ease-in-out 1 forwards;
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            span {
                cursor: pointer;
                font-size: 1.9em;
    
                &:hover {
                    color: $color_secondary;
                }
            }
        }
    }

    .loading {
        height: 40px;
        width: 135px;
        opacity: 0;
        animation: blink-leaf 4s ease-in-out 1 forwards 2.6s;
        color: $color_secondary;
        display: inline-block;
        position: relative;
        font-size: 1.4em;
        font-family: "Abel", sans-serif;
        box-sizing: border-box;
        display: flex;
        align-items: baseline;

        &::after {
            content: '';  
            display: block;
            width: 5px;
            height: 5px;
            margin-left: 5px;
            background: $color_secondary;
            box-sizing: border-box;
            animation: animloader 1s linear infinite;
        }
    }

    #main-logo {
        overflow: visible;

        path {
            opacity: 0;
            -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .25));
            filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .25))
        }
        
    }
    
    #main-logo path:nth-child(1) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 0.2s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(2) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 0.4s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(3) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 0.6s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(4) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 0.8s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(5) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 2.2s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(6) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 1s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(7) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 1.2s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(8) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 1.4s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(9) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 1.6s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(10) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 1.8s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(11) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 2s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(12) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay:  2.4s;
        animation-fill-mode: forwards;
    }
    
    #main-logo path:nth-child(13) {
        animation: 2s ease-in-out blink-leaf;
        animation-iteration-count: 1;
        animation-delay: 2.4s;
        animation-fill-mode: forwards;
    }
}



@keyframes blink-leaf {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes move-leaf1 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(-23px, 14px);
    }
}

@keyframes move-leaf2 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(23px, 14px);
    }
}

@keyframes move-leaf3 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(-40px, -5px);
    }
}

@keyframes move-leaf4 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(-33px, -20px);
    }
}

@keyframes move-leaf5 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(26px, -11px);
    }
}

@keyframes move-leaf6 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(-20px, -40px);
    }
}

@keyframes move-leaf7 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(-22px, -16px);
    }
}

@keyframes move-leaf8 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(-16px, -40px);
    }
}

@keyframes move-leaf9 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(-20px, -16px);
    }
}

@keyframes move-leaf10 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(16px, -16px);
    }
}

@keyframes move-leaf11 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(15px, -20px);
    }
}

@keyframes move-leaf12 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(9px, -40px);
    }
}

@keyframes move-leaf13 {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        opacity: 0;
        transform: translate(40px, 0px);
    }
}



@keyframes loader-disappears {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes animloader {
    0% {
      box-shadow: 10px 0 rgba(255, 255, 255, 0), 20px 0 rgba(255, 255, 255, 0);
    }
    50% {
      box-shadow: 10px 0 $color_secondary, 20px 0 rgba(255, 255, 255, 0);
    }
    100% {
      box-shadow: 10px 0 $color_secondary, 20px 0 $color_secondary;
    }
  }