.home-container {
    height: 100vh;
    overflow: hidden;

    h1, h2, h3, h4, p {
        span {
            color: $color_secondary;
        }
    }

    .foreground {
        height: 100%;
        width: 100%;

        main {
            height: 100%;
            width: 100%;

            #home {
                height: 100vh;
                width: 100%;
                display: flex;
                overflow: hidden;
                position: relative;

                #top-of-page {
                    position: fixed;
                    z-index: 9;
                    bottom: 3%;
                    right: 3%;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        height: 35px;
                        width: 35px;
                        margin-bottom: 5px;
                    }

                    p {
                        width: 45px;
                        font-size: 0.8em;
                        text-align: center;
                        font-family: 'Arapey', serif;
                    }
                }

                .home-right-column {
                    height: 100%;
                    width: 10%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    right: 0;

                    .swiper-nav {
                        height: 52%;
                        width: 50px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 25px;
            
                        .square {
                            height: 18px;
                            width: 18px;
                            background-color: $transparent_blue;
                            cursor: pointer;
                        }
            
                        .square-active {
                            background-color: rgba(194, 184, 135, 0.8);
                            cursor: default;
                        }
                    }
                }
                
            }

            .transition {
                width: 100%;
                height: 110px;
                display: flex;
                align-items: center;
                padding: 0 3%; 
                
                h2 {
                    font-size: 2.5em;
                    opacity: 0;
                }   
            }
        }   
    }
}

