#services {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    .services-container {
        height: 100%;
        width: 25%;

        .services-title {
            height: 200px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 60px;

            svg {
                margin-bottom: 20px;

                rect {
                    opacity: 0;
                }            
            }

            .diamond-active {
                width: 100%;
                padding: 0 10%;

                rect{

                    &:nth-child(1) {
                        animation: opa0to1 1s linear forwards 1.40s;
                    }
                    &:nth-child(2) {
                        animation: opa0to1 1s linear forwards 1.05s;
                    }
                    &:nth-child(3) {
                        animation: opa0to1 1s linear forwards 0.7s;
                    }
                    &:nth-child(4) {
                        animation: opa0to1 1s linear forwards 0.35s;
                    }
                    &:nth-child(5) {
                        animation: opa0to1 1s linear forwards 0s;
                    }
                }
            }
        }

        .services-nav {
            height: calc(100% - 150px);
            width: 100%;
            padding: 0 12%;

            ul {
                li {
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    font-size: 1.1em;
                    cursor: pointer;
                    margin-bottom: 25px;

                    span {
                        margin-left: 35px;
                    }
                }
            }
        }
    }

    .products-container {
        width: 75%;
        height: 100%;
        position: relative;

        .products-img {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            pointer-events: none;

            img {
                height: 100%;
                width: 100%;
                object-position: left;
            }
        }

        .products-list {
            height: 100%;
            width: 37%;
            min-width: 575px;
            margin-left: 7%;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            position: absolute;
            background-color: $light_gray;
            transform: scaleY(0);
            transform-origin: bottom;

            .products-type {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                box-shadow: inset 13px 0 $color_secondary;

                h3 {
                    padding: 0 45px;
                    font-size: 1.8em;
                }
            }

            .products {
                width: 100%;
                height: calc(100% - 100px);
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                background-color: #232B33;
                padding: 0 25px;
            }
        }
    }
}

@keyframes productsUp {

    0%{
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}